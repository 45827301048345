<template>
    <div class="wrap">
        <van-search
            v-model="instanceName"
			clearable
            placeholder="请输入流程名字搜索"
            @search="searchdata"
			class="searchPlace"
        ></van-search>
        <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
        >
            <van-list
                v-model="loading"
                :finished="finished"
                @load="getData"
            >
                <template v-if="list.length > 0">
                    <van-cell-group
                        v-for="(item, index) in list"
                        :key="index"
                        :style="{
                            'border-left': '4px solid',
                            'border-radius': '6px',
                            'border-image' : `${resultMap[item.result].bgColor}`
                        }"
                        @click="
                            $router.push({
                                path:
                                    '/historyInfo?id=' +
                                    item.id +
                                    '&page=history'  +
                                    '&taskId=' +
                                    (item.tasks ? item.tasks[0]?.id : null)+
                                    '&processDefinitionId=' +
                                    item.processDefinitionId +
                                    isHasNav,
                            })
                        "
                        :url="
                            '/#/historyInfo?id=' +
                            item.id +
                            '&page=history'  +
                            '&taskId=' +
                            (item.tasks ? item.tasks[0]?.id : null)+
                            '&processDefinitionId=' +
                            item.processDefinitionId +
                            isHasNav
                        "
                    >
                        <van-cell
                            is-link
                            :clickable="true"
                        >
                        <template #right-icon>
                            <span :style="{ color: resultMap[item.result].color }">{{resultMap[item.result].text}}</span>
                            <van-icon name="arrow" :color="resultMap[item.result].color"  />
                        </template>
                            <template #title>
                                <span style="font-weight: 600;font-size: 15px;">
                                    {{ item.instanceName }}
                                </span>
                            </template>
                        </van-cell>

                        <div class="nodecEll">
                            <div class="cellTitle">
                                <span>发起人：</span>
                                <span>{{item.startUserName}}</span>
                            </div>
                            <div class="cellTitle">
                                <span>提交时间：</span>
                                <span>{{formatTime(item.createTime)}}</span>
                            </div>
                        </div>
                    </van-cell-group>
                </template>

                <div
                    class="empty"
                    v-if="list.length == 0 && finished"
                >
                    <van-empty
                        description="没有内容，空空如也~"
                        :image="require('@/assets/img/mag_pic_noinformation.svg')"
                    ></van-empty>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import { getHistoryFlowList } from "@/api/flow";
    import { formatTime } from "@/utils/DateUtil";
    import { resultMap } from "@/constants/map";

    export default {
        data() {
            return {
                instanceName:"",
                loading: false,
                finished: false,
                refreshing: false,
                page: 1,
                size: 10,
                list: [],
            };
        },
        components: {},
        watch: {},
        created() {},
        activated() {
            //需要刷新的页面方法
            this.onRefresh();
        },
        methods: {
            searchdata() {
                this.list = [];
                this.onRefresh();
                // this.page = 1;
                // this.list = [];
                // this.getData();
            },
            getData() {
                try {
                    let params = {
                        pageNo: this.page,
                        pageSize: this.size,
                    };
                    if(this.instanceName){
                        params.instanceName = this.instanceName;
                    }
                    getHistoryFlowList(params).then((res) => {
                        if (res.status == 200) {
                            if (this.refreshing) {
                                this.list = [];
                                this.refreshing = false;
                            }
                            if (res.data.list && res.data.list.length > 0) {
                                this.list = this.list.concat(res.data.list);
                                this.page += 1;
                            }
                            this.loading = false;
                            if (this.list.length >= res.data.total) {
                                this.finished = true;
                            }
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    this.finished = true;
                    //TODO handle the exception
                }
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.loading = true;
                this.page = 1;
                // 重新加载数据
                this.getData();
            },
            formatTime,
        },
        computed: {
            // 从哪个页面跳转过来的
            isHasNav() {
                return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
            },
            resultMap() {
                return resultMap;
            },
        },
    };
</script>

<style lang="less" scoped>
    /deep/.van-cell{
        display:flex;
        align-items:center;
        padding:8px;
    }
    /deep/.van-cell::after{
        right: 8px;
        left: 8px
    }
    .wrap {
        padding: 0 10px;

        .nodecEll{
            display:flex;
            .cellTitle{
                padding:8px;
                font-size: 12px;
                }
           
        }
        .searchPlace{
			/deep/.van-cell__value{
				display: flex;
				align-items: center;
			}
            /deep/.van-field__body{
                width:100%
            }
			
		}

        .title {
            display: flex;
            align-items: center;
            .line {
                width: 0.2rem;
                height: 0.8rem;
                background: #037ae7;
                margin-right: 10px;
            }
        }

        /deep/ .blue .van-cell__value {
            color: #037ae7;
        }

        /deep/ .red .van-cell__value {
            color: rgb(255, 77, 79);
        }

        /deep/ .van-cell-group {
            margin: 8px 0;
        }
    }
</style>
